import React from "react"
import ClientManagement from "../components/widgets/ClientManagement"
import ClientOnboarding from "../components/widgets/ClientOnboarding"
import ExpenseAndIncomeManagement from "../components/widgets/ExpenseAndIncomeManagement"
import TaskManagementAndReporting from "../components/widgets/TaskManagementAndReporting"
import { featuresPostTypeSlug } from "./Global"

// Hero Section
export const heroSection = {
  title: `Simplify Your <span class="text-secondary">Accounting Practices</span> With Our Innovative Features`,
  description: `Synkli simplifies accountants' accounting practice management. Enjoy managing your clients, team members, and financial data in one place. Our powerful features empower accountants to deliver exceptional service and optimise accounting workflows.`,
  image: {
    path: `page-features--hear-section-banner`,
    alt: `page-features--hear-section-banner`
  }
}


export const featuresFreeFallElementsSection = {
  title: `Businesses and Accountants Can Keep All Their Financial Data in Just One Place. No More Digging Through Files or Multiple Systems. Welcome to Paperless Accounting Practice Management!`,
  button: {
    to: "#our_features_section",
    href: "#our_features_section",
    text: "Our Features",
    ec: "",
  },
  featureElements: [
    {
        text: `Client Onboarding`,
        ec: `bg-[#C6EBF9]`
    },
    {
        text: `Task Management`,
        ec: `bg-[#F9CAC6]`
    },
    {
        text: `Mileage Tracker`,
        ec: `bg-[#EBEBEB]`
    },
    {
        text: `Expense Management`,
        ec: `bg-[#C8E3FF]`
    },
    {
        text: `Income Management`,
        ec: `bg-[#FFFBD0]`
    },
    {
        text: `Online Signature`,
        ec: `bg-[#F3D9B7]`
    },
    {
        text: `Client Management`,
        ec: `bg-[#DEFFD7]`
    },
    {
        text: `Wordpress Plugin`,
        ec: `bg-[#FBE2FF]`
    },
    {
        text: `Outlook Add-in`,
        ec: `bg-[#C5FDF2]`
    },
    {
        text: `Xero Connection`,
        ec: `bg-[#D9D3FF]`
    },
  ]
}

// Features Section
export const featuresSection = {
  title: 'Key Features: Your Path To Efficiency And Growth',
  features : [
    {
      componentEC: "",
      title: "Effortless Client Onboarding",
      description:
        "Enhance your accounting practice with our easy-to-use, customisable form creation feature. It helps you gather all the necessary information from clients quickly and efficiently. You can tailor these forms to suit your specific needs, ensuring that the onboarding process is smooth and seamless. This means better communication and more accurate data collection from the start, helping you manage your clients more effectively.",
      buttonn: {
        to: featuresPostTypeSlug + "client-onboarding/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <ClientOnboarding />
    },
    {
      componentEC: "",
      title: "Task Management and Reporting",
      description:
        "Streamline your accounting tasks with Synkli's powerful task management and reporting tools. These features let you easily assign tasks, track your team’s progress, and get detailed insights into their performance. You can see what everyone is working on, spot any bottlenecks, and ensure that tasks are completed on time. Also, you can generate comprehensive reports that help you understand how your practice is performing and where you can improve.",
      buttonn: {
        to: featuresPostTypeSlug + "task-management-and-reporting/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <TaskManagementAndReporting />
    },
    {
      componentEC: "",
      title: "Client Management",
      description:
        "Keep all your client information organised and accessible with our intuitive client management feature. You can effortlessly collect and store client data, gather signatures, and manage documents all in one place. Each client has a dedicated file manager, allowing for real-time file sharing between you and your clients. This ensures that you always have the latest information at your fingertips and can communicate with your clients more efficiently.",
      buttonn: {
        to: featuresPostTypeSlug + "client-management/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <ClientManagement />
    },
    {
      componentEC: "",
      title: "Expense & Income Management",
      description:
        "You can gain a clear understanding of your clients' financial situations with our detailed dashboards. These dashboards provide a visual overview of their income and expenses, making it easier to see their current financial position. You can also generate preprocessed profit and loss statements, rental schedules, or deduction summaries tailored to different types of clients, whether they are business owners, rental property owners, sole traders, or salaried individuals. This helps you provide better advice and ensure that your clients maximise their financial outcomes.",
      buttonn: {
        to:  featuresPostTypeSlug + "expense-and-income-management/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <ExpenseAndIncomeManagement />
    },
    {
      componentEC: "",
      title: "Synkli Capture",
      description:
        `Let’s revamp your WordPress site with SYNKLI Capture. This plugin seamlessly integrates with SYNKLI Portal, and its latest feature will streamline lead management by automatically syncing with your Portal and eliminating manual data entry work. Personalise your forms to capture relevant information and acquire instant updates on the SYNKLI dashboard. SYNKLI Capture refines your workflow. Focus on what matters—turning leads into loyal customers. Start with automated syncing and streamlined management today.`,
      buttonn: {
        to: featuresPostTypeSlug + "synkli-capture/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `synkli-wordpress-plugin`,
        alt: `synkli-wordpress-plugin`
      }
    },
    {
      componentEC: "",
      title: "Synkli Add-In for Outlook",
      description:
        `Ready to boost productivity with a free Synkli add-in for Outlook? This feature smoothly combines with the Synkli portal, helping users manage leads directly from their Outlook. Simply use your existing Synkli account to automatically synchronise tasks and leads with the portal. Simplify your workflow and control everything from one location. The Synkli Add-In also offers a client lookup feature, helping you find complete information about your existing client saving considerable time.`,
      buttonn: {
        to:  featuresPostTypeSlug + "outlook-addin/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `synkli-outlook-addin`,
        alt: `synkli-outlook-addin`
      }
    },
    {
      componentEC: "",
      title: "Connect to Xero",
      description:
        `Let’s explore another exciting feature that seamlessly links Synkli with Xero. This synchronisation automatically sends your data to Xero in a minute. Gone are the days of inconsistencies or delays. You can access and update everything from any of the platforms. Whether you're reworking client information on Synkli or managing finances in Xero, the connected data ensures both systems are consistently accurate and up-to-date. This is time-saving and reduces the chance of errors. Now, concentrate on delivering outstanding service to your clients!`,
      buttonn: {
        to: featuresPostTypeSlug + "connect-to-xero/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `synkli-connect-to-zero`,
        alt: `synkli-connect-to-zero`
      }
    },
    {
      componentEC: "",
      title: "Online Signature",
      description:
        `Seeking a client's signature has become more streamlined lately. It’s time for accountants to employ a paperless approach, and Synkli offers an online signature service. You can sign documents online quickly and securely, saving time and effort. Our digital signature functionality ensures your documents are legally binding and safely stored, enhancing the efficiency of client data management.`,
      buttonn: {
        to:  featuresPostTypeSlug + "online-signature/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--online-signature-widget`,
        alt: `features-page--online-signature-widget`
      }
    },
    {
      componentEC: "",
      title: "Claim Deductions",
      description:
        `Synkli’s ‘Scan and Go’ feature simplifies deduction claims by enabling businesses to scan and submit receipts instantly through a mobile app. It automatically organises and tracks expenses, allowing faster reimbursements and reducing manual errors. Accountants can review claims in real time, improving workflow efficiency and maximising tax savings. This tool helps businesses manage receipts on the go, streamlining the entire reimbursement process.`,
      buttonn: {
        to: featuresPostTypeSlug + "claim-deductions/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--claim-deductions`,
        alt: `features-page--claim-deductions`
      }
    },
    {
      componentEC: "",
      title: "Mileage Tracker",
      description:
        `Days of manually logging your trips are over with Synkli’s trip tracker feature. Now, you can automatically track your mileage for business and personal trips. Effortlessly log and report every mile to ensure you maximise your deduction claims accurately and efficiently. Automate your trip expense reporting and maximise your tax benefits.`,
      buttonn: {
        to: featuresPostTypeSlug + "mileage-tracking/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--mileage-tracker`,
        alt: `features-page--mileage-tracker`
      }
    },
    {
      componentEC: "",
      title: "Team Management",
      description:
        `Synkli’s Team Management feature helps accountants streamline their workflow by organising team roles, tracking progress, and ensuring smooth collaboration. Accountants can assign tasks, monitor performance, and centralise communication, making coordinating with team members and clients easy. With real-time updates and role management, Synkli enhances productivity and simplifies practice management, helping teams stay aligned and focused on delivering efficient client accounting services.`,
      buttonn: {
        to: featuresPostTypeSlug + "team-management/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--team-management`,
        alt: `features-page--team-management`
      }
    },
    {
      componentEC: "",
      title: "Document Hub",
      description:
        `Document Hub, A file manager, simplifies accounting workflows by organising and storing client financial documents, including invoices and tax returns, in a single, secure location. Businesses and accountants can easily upload, review, and share files, facilitating collaboration and enhancing efficiency. The digital document-sharing tool supports online signatures from clients or multiple recipients for quick and efficient accounting record management and tax processes.`,
      buttonn: {
        to: featuresPostTypeSlug + "document-hub/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--document-hub`,
        alt: `features-page--document-hub`
      }
    },
    {
      componentEC: "",
      title: "Job manager",
      description:
        `Synkli’s Job Manager helps accountants optimise accounting practice management through workflow automation and simplified task communication. Accountants can easily create, assign, and track jobs, set milestones, and manage recurring tasks. The platform facilitates secure sharing of client data and enhances collaboration through features like instant messaging and automated reminders. This improves efficiency, saves time, and reduces errors, helping accountants focus on higher-level tasks while ensuring client satisfaction.`,
      buttonn: {
        to: featuresPostTypeSlug + "job-manager/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--job-manager`,
        alt: `features-page--job-manager`
      }
    },
  ]
}